export default [
  {
    key: 'amazonia_limit',
    labelKey: 'amazonia_limit',
    color: '#B14018',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/LIMITERAISG.zip',
    index: 1,
  },
  {
    key: 'biome',
    labelKey: 'biome',
    color: '#EB2F06',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/BIOMES_ALL.zip',
    index: 2,
  },
  {
    key: 'watershed',
    labelKey: 'watershed',
    color: '#16DFF2',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/CUENCAS_NIVEL_1_PAIS.zip',
    index: 3,
  },
  {
    key: 'indigenous_territorie',
    labelKey: 'indigenous_territorie',
    color: '#6A3D9A',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/INDIGENOUS_TERRITORIES.zip',
    index: 4,
  },
  {
    key: 'state_protected_areas',
    labelKey: 'state_protected_areas',
    color: '#1A7836',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/PROTECTED_AREAS_DEPTALES.zip',
    index: 5,
  },
  {
    key: 'federal_protected_areas',
    labelKey: 'federal_protected_areas',
    color: '#C51B7D',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Base consolidada RAISG. Vide Metadados en https://isa.to/3WtHlPq',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_6/download/layers/PROTECTED_AREAS_NACIONALES.zip',
    index: 6,
  },
];
